export default defineNuxtRouteMiddleware((to, from) => {
  if (from.meta.slide && !to.meta.slide) {
    to.meta.pageTransition = {
      name: 'fix',
      mode: 'in-out'
    }

    from.meta.pageTransition = {
      name: 'slide-left',
      mode: 'in-out'
    }

    return
  }

  if (!from.meta.slide && to.meta.slide) {

    from.meta.pageTransition = {
      name: 'fix',
      mode: 'in-out',
      duration: {
        enter: 0,
        leave: 900
      }
    }

    to.meta.pageTransition = {
      name: 'slide-right',
      mode: 'in-out',
      duration: {
        enter: 900,
        leave: 1000
      }
    }

    return
  }

  if (!from.meta.slide && !to.meta.slide) {
    return
  }

  from.meta.pageTransition = {
    name: 'slide-left',
    mode: 'out-in',
    duration: {
      enter: 0,
      leave: 500
    }
  }

  to.meta.pageTransition = {
    name: 'slide-right',
    mode: 'out-in',
    duration: {
      enter: 500,
      leave: 1000
    }
  }

})
import { useInitState, useInitClientState } from "~/composables/useInitState"

export default defineNuxtPlugin(async ({ $pinia }) => {
  if (import.meta.client) {
    await useInitClientState()
    console.log('Client store initialized.')
    return
  }
  await useInitState()
  console.log('Store initialized.')
})
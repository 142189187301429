import { DirectiveBinding } from 'vue';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('position-popup', {
        mounted(el: HTMLElement, binding: DirectiveBinding) {
            updatePosition(el, binding)        
        },
        updated(el: HTMLElement, binding: DirectiveBinding) {
            updatePosition(el, binding)        
        },
    });

    function updatePosition(el: HTMLElement, binding: DirectiveBinding) {
        const targetElement = document.querySelector(binding.value);
        const isLeft = binding.arg === 'left';
        const isCenter = binding.arg === 'center'
        const isBottom = binding.arg === 'bottom'

        if (targetElement) {
            const rect = targetElement.getBoundingClientRect();
            const elRect = el.getBoundingClientRect()

            el.style.position = 'absolute'
            el.style.top = `-${rect.height + elRect.height}px`

            if (isLeft) {
                el.style.right = 'auto'
                el.style.left = `15px`
            } else if (isCenter) {
                el.style.left = '50%'
                el.style.transform = 'translate(-50%, 0)'
                el.style.top = `-${rect.height + elRect.height - 20}px`
            } else if (isBottom) {
                el.style.left = '0'
                el.style.bottom = `-${rect.height}px`
                el.style.top = 'auto'
            } else {
                el.style.left = `auto`
                el.style.right = `${rect.width - 20}px`
            }
        }
    }
})
<script setup lang="ts">

interface Props {
  scrollable?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  scrollable: false,
})

const loaded = ref(false)
const hidden = ref(false)
const fadingOut = ref(false)


const loader = ref<HTMLElement | null>(null)
const logo = ref<HTMLElement | null>(null)
const leftClouds = ref<SVGElement | null>(null)
const rightClouds = ref<SVGElement | null>(null)
const loaderComponent = ref<HTMLElement | null>(null)

const hideLoader = (withFade=true) => {
  if (withFade && loaderComponent.value) {
    fadingOut.value = true
    setTimeout(() => {
      hidden.value = true
    }, 2000);
    return
  }
  hidden.value = true
}

const onLoad = () => {
  if (loader.value) {
    loader.value.style.display = 'none'
  }

  if (leftClouds.value && rightClouds.value) {
    leftClouds.value.style.display = 'block';
    rightClouds.value.style.display = 'block';

    setTimeout(() => {
      if (logo.value) {
        logo.value.style.display = 'block';
        logo.value.style.opacity = '1';
      }
      setTimeout(() => {
        if (props.scrollable) {
          const layoutContainer = document.querySelector('.layout-container');
          const appContent = document.querySelector('.app-content');
          const landingPage = document.querySelector('.landing-page');

          if (!layoutContainer || !appContent) {
            hideLoader()
            return
          }

          (layoutContainer as HTMLElement).style.position = 'static';
          (appContent as HTMLElement).style.overflow = 'hidden';
          if (landingPage) {
            (landingPage as HTMLElement).style.overflow = 'hidden';
          }

          loaded.value = true

          useIntersectionObserver(
            loaderComponent,
            ([{ isIntersecting }]) => {
              if (isIntersecting) return;

              (layoutContainer as HTMLElement).style.position = 'fixed';
              (appContent as HTMLElement).style.overflow = 'auto';
              if (landingPage) {
                (landingPage as HTMLElement).style.overflow = 'auto';
              }
              hideLoader(false);
            },
            {
              threshold: 0.01
            }
          )

          return
        }

        hideLoader()
      }, 3000);
    }, 3500); 
  }
}

const nuxtApp = useNuxtApp()
nuxtApp.hook('page:loading:end', () => {
  onLoad()
})
</script>

<template lang="pug">
  #loader-component(ref="loaderComponent" :class="{ 'loader-component--scrollable': props.scrollable, 'loader-component--loaded': loaded, 'loader-component--hidden': hidden, 'loader-component--fading-out': fadingOut }")
    .loader-wrapper
      img.loader(src="/images/loader.png" alt="spin" ref="loader")
      .logo(ref="logo")
        img(src="/images/horizontal-logo.png" alt="left-clouds")
      .left-clouds(ref="leftClouds")
        img(src="/images/left-clouds.png" alt="left-clouds")
      .right-clouds(ref="rightClouds")
        img(src="/images/right-clouds.png" alt="right-clouds")
    .description(v-if="props.scrollable" v-show="loaded")
      p CREME BRULEE – это первая социальная сеть в России о сексуальном образовании, объединившая множество комьюнити с экспертами.
</template>

<style lang="scss" scoped>
#loader-component {
  align-items: center;
  background: linear-gradient(248.58deg, #FFF8FC -3.61%, #FFEDEB 23.41%, #FFFFFF 50.43%, #FFF6F5 77.45%, #FFE9F5 104.46%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100dvh;
  left: 0;
  right: 0;
  position: fixed;
  text-align: center;
  width: 100vw;
  z-index: 7;
  overflow: hidden;

  .loader-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    width: 100%;
  }

  &.loader-component--hidden {
    display: none;
  }

  &.loader-component--fading-out {
    opacity: 0;
    transition: opacity 2s;
  }

  &.loader-component--scrollable {
    &.loader-component--loaded {
      height: 200dvh;
      position: relative;
    }
    
    .description {
      height: 100dvh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        text-align: center;
        color: $neutral-700;
        width: get-vw(961);
        max-width: 961px;
      }
    }
  }
}

.loader {
  height: get-vw(138);
  width: get-vw(138);
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.logo {
  height: get-vw(155);
  width: get-vw(1789);
  animation: scale-up 5s ease forwards;
  opacity: 0;
  display: none;

  img {
    height: 100%;
    width: 100%;
  }
}

@keyframes scale-up {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

@include mobile {
  .logo {
    animation: mobile-scale-up 5s ease forwards;
  }
}

@keyframes mobile-scale-up {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(0.9);
  }
}

.left-clouds {
  height: get-vw(1354);
  width: get-vw(3180);
  position: absolute;
  transform: translateX(-100vw);
  display: none;
  animation: left-clouds-in 3s ease forwards 0.5s, left-clouds-out 3s ease forwards 3.5s;
}

.right-clouds {
  height: get-vw(1440);
  width: get-vw(3381);
  position: absolute;
  transform: translateX(-100vw);
  display: none;
  animation: right-clouds-in 3s ease forwards 0.5s, right-clouds-out 3s ease forwards 3.5s;
}

@keyframes left-clouds-in {
  0% {
    transform: translateX(-100vw);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes left-clouds-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100vw);
  }
}

@keyframes right-clouds-in {
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes right-clouds-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100vw);
  }
}

@include mobile {
  .loader {
    height: get-vw(72, "sm");
    width: get-vw(72, "sm");
  }
}
</style>
import { defineStore } from "pinia"
import { useGraphQLQuery } from '@/composables/useApolloClient'
import type { 
  Category, 
  CommonSubCategory, 
  CategoriesState, 
  CategoryGroup,
} from '@/interfaces/categories'

export type CategoriesResponse = {
  categories: {
    data: CategoryData[];
  }
}

export type ImageResponse = {
  image: {
    data: {
      id: number;
      attributes: {
        url: string;
        width: string;
        height: string;
      }
    }
  }
}

export type SubCategoryData = {
  id: number;
  attributes: {
    label: string;
    description: string;
  }
}

export type SubCategoriesResponse = {
  subCategories: {
    data: CommonSubCategoryData[]
  }
}

export type CommonSubCategoryData = {
  id: string;
  attributes: {
    label: string;
    description: string;
    createdAt: string;
    icon: {
      data: {
        attributes: {
          name: string
        }
      }
    }
  }
}

export type CategoryGroupsResponse = {
  categoryGroups: {
    data: CategoryGroupData[]
  }
}

export type CategoryGroupData = {
  id: string;
  attributes: {
    label: string;
    categories: CategoriesResponse;
  }
}

export type CategoryData = {
  id: number;
  attributes: {
    owner: string;
    ownerAvatar: string;
    label: string;
    image: ImageResponse;
    description: string;
    full_description: string;
    icon: {
      data: {
        attributes: {
          url: string;
        }
      }
    }
    sub_categories: { 
      data: SubCategoryData[];
    }
  }
}

export const useCategoriesStore = defineStore('categories', {
  state: (): CategoriesState => ({
    categories: [],
    subCategories: [],
    categoryGroups: [],
    isCategoriesFetching: false,
    isSubCategoriesFetching: false,
    isCategoryGroupsFetching: false,
  }),
  getters: {
    getCategoryById: (state: CategoriesState) => (id: number | string | string[]) => {
      return state.categories.find((category) => category.id == id)
    },
    getSubCategoryById: (state: CategoriesState) => (id: number) => {
      return state.categories
        .map((category) => category.subcategories)
        .flat()
        .find((subcategory) => subcategory.id === id)
    },
    getCommonSubCategoryById: (state: CategoriesState) => (id: number) => {
      return state.subCategories.find((subCategory) => subCategory.id === id)
    },
  },
  actions: {
    async fetchCategories() {
      const { value, addToCache } = await useDataCache<Category[]>('categories')
      if (value) {
        this.categories = value
        return
      }

      this.isCategoriesFetching = true

      const { fetch } = useGraphQLQuery<CategoriesResponse>(gql`
        query {
          categories(pagination: { start: 0, limit: 1000 }) {
            data {
              id
              attributes {
                full_description
                owner {
                  data {
                    id
                    attributes {
                      username
                      avatar {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                label
                banner_image {
                  data {
                    id
                    attributes {
                      url
                      height
                      width
                    }
                  }
                }
                image {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                icon {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                subscriptions_count
                sub_categories {
                  data {
                    id
                    attributes {
                      label
                      description
                      icon {
                        data {
                          attributes {
                            name
                          }
                        }
                      }
                    }
                  }
                }
                description
              }
            }
          }
        }
      `)
      const data = await fetch()

      if (data && data.categories) {
        const categories: Category[] = data.categories.data.map((category: any) => {
          return {
            avatar: category.attributes.image?.data?.attributes?.url,
            owner: category.attributes.owner.data?.attributes?.username,
            ownerId: category.attributes.owner.data?.id,
            ownerAvatar: category.attributes.owner.data?.attributes?.avatar?.data?.attributes?.url,
            description: category.attributes.description,
            fullDescription: category.attributes.full_description,
            id: category.id,
            label: category.attributes.label,
            icon: category.attributes.icon?.data?.attributes.url,
            image: category.attributes.banner_image.data.attributes.url,
            imageWidth: category.attributes.banner_image.data.attributes.width,
            imageHeight: category.attributes.banner_image.data.attributes.height,
            subcriptions: category.attributes.subscriptions_count,
            subcategories: category.attributes.sub_categories?.data.map((subcategory: any) => {
              return {
                id: subcategory.id,
                label: subcategory.attributes.label,
                description: subcategory.attributes.description,
                icon: subcategory.attributes.icon?.data?.attributes.name,
              }
            })
          }
        })
        this.categories = categories
        this.isCategoriesFetching = false

        await addToCache(categories, ['categories'], 30 * 60 * 1000)
      }
    },

    async fetchSubCategories() {
      this.isSubCategoriesFetching = true

      const { fetch } = useGraphQLQuery<SubCategoriesResponse>(gql`
        query {
          subCategories {
            data {
              id
              attributes {
                label
                description
                icon {
                  data {
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `)

      let data
      try {
        data = await fetch()
      } catch (e) {
        console.error('Error fetching category groups:', e)
      }

      if (data && data.subCategories) {
        const subCategories: CommonSubCategory[] = data.subCategories.data.map((subCategory: any) => {
          return {
            id: subCategory.id,
            label: subCategory.attributes.label,
            description: subCategory.attributes.description,
            icon: subCategory.attributes.icon?.data?.attributes.name,
            createdAt: subCategory.attributes.createdAt,
          }
        })
        this.subCategories = subCategories
        this.isSubCategoriesFetching = false
      }
    },

    async fetchCategoryGroups() {
      const { value, addToCache } = await useDataCache<CategoryGroup[]>('categoryGroups')
      if (value) {
        this.categoryGroups = value
        return
      }

      this.isCategoryGroupsFetching = true

      const { fetch } = useGraphQLQuery<CategoryGroupsResponse>(gql`
        query {
          categoryGroups(pagination: { start: 0, limit: 50 }) {
            data {
              id
              attributes {
                label
                categories {
                  data {
                    id
                    attributes {
                      label
                      full_description
                      banner_image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                      sub_categories {
                        data {
                          id
                          attributes {
                            label
                            description
                          }
                        }
                      }
                      icon{
                        data{
                          attributes{
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `)

      const data = await fetch()

      if (data && data.categoryGroups) {
        const categoryGroups: [] = data.categoryGroups.data.map((categoryGroup: any) => {
          return {
            id: categoryGroup.id,
            label: categoryGroup.attributes.label,
            categories: categoryGroup.attributes.categories.data,
          }
        })
        this.categoryGroups = categoryGroups
        this.isCategoryGroupsFetching = false

        await addToCache(categoryGroups, ['categoryGroups'], 30 * 60 * 1000)
      }
    },
  }
})
